<template>
  <BaseModal
    headerText="Consulter la liste"
    :is-open="isOpen"
    btnValidationText="Ajouter les participants selectionnés"
    v-on="$listeners"
    :is-action-needed="false"
    @validate="setNewParticipant()"
  >
    <template #modal-content>
      <div class="content-modal d-flex flex-column px-1">
        <div class="d-flex align-center my-4">
          <span
            v-text="'Les personnes qui sont présentes dans la liste'"
            class="text-subtitle"
          />
          <v-divider class="ml-4" color="#dfe5f9"></v-divider>
        </div>
        <div class="table-container">
          <v-data-table
            class="id-fuse-table"
            :headers="headers"
            :items="participantListe"
            disable-sort
          >
            <template #item.fullname="{item}">
              <span
                v-if="item"
                v-text="
                  `${item.UTI_Utilisateur.CT_Nom} ${item.UTI_Utilisateur.CT_Prenom}`
                "
              />
            </template>
            <template #item.Naissance="{item}">
              <span>
                {{
                  $moment(item.UTI_Utilisateur.DN_DateNaissance).format(
                    "DD/MM/YYYY"
                  )
                }}
              </span>
            </template>
            <template #item.LicenceNumero="{item}">
              <v-chip
                v-if="item.UTI_Utilisateur.LicenceNumero == null"
                color="grey"
                text-color="white"
                v-text="'N.C'"
              />
              <v-chip
                v-else
                color="primary"
                text-color="white"
                v-text="formatToLicence(item.UTI_Utilisateur.LicenceNumero)"
              />
            </template>
            <template #item.actions="{item}">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="removeUserFromList(item)"
                    fab
                    x-small
                    outlined
                    color="red"
                  >
                    <v-icon color="red"> mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Retirer la personne de la liste</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseModal from "../../../../components/utils/modals/BaseModal.vue";

export default {
  name: "IDFuseUserInListModal",
  components: { BaseModal },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    idListe: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      structureListes: (state) => state.structure.structureList,
    }),
    participantListe() {
      return this.structureListes.find((liste) => liste.id === this.idListe)
        .STR_StructureListeUtilisateurs;
    },
  },
  data() {
    return {
      userSelectable: [],
      userToAdd: [],
      headers: [
        {
          text: "Numero de licence",
          align: "center",
          value: "LicenceNumero",
        },
        {
          text: "Personne",
          value: "fullname",
        },
        {
          text: "email",
          value: "UTI_Utilisateur.CT_Email",
        },
        {
          text: "Date de naissance",
          align: "center",
          value: "Naissance",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    ...mapActions("structure", ["removeUserFromStructureListe"]),
    async removeUserFromList(item) {
      await this.removeUserFromStructureListe({
        ID_UtilisateurListe: item.id,
        ID_Liste: this.idListe,
        ID_Utilisateur: item.UTI_Utilisateur.id,
      });
    },
    formatToLicence(numero) {
      const licence = `${numero}`;
      return `${licence.slice(0, 3)} ${licence.slice(3, 6)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-modal {
  font-family: Roboto;
  font-style: normal;
  color: #020918;
  .text-subtitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
}
</style>
<style scoped>
::v-deep .v-data-table-header th {
  background: #39558b !important;
  color: white !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
}
::v-deep .id-fuse-table td {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000;
}
</style>
