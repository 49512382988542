<template>
  <BaseModal
    headerText="Gestion de l’Abonnement à la plateforme d’emailing"
    :is-open="isOpen"
    btnValidationText="Annuler la prolongation"
    btnCancelText="Fermer"
    cancel-btn
    @validate="cancelSubscription()"
    :is-validation-btn-disabled="!acceptTerms"
    v-on="$listeners"
  >
    <template #modal-content>
      <div class="content-modal d-flex flex-column px-1">
        <div class="d-flex align-center my-6">
          <span class="text-green"> Abonnement actif </span>

          <span
            class="subtext ml-6"
            v-text="`Période de validité : ${validSeason}`"
          />
        </div>
        <div class="d-flex align-center my-2">
          <span
            v-text="'Description de la future transaction'"
            class="text-subtitle"
          />
          <v-divider class="ml-4" color="#dfe5f9"></v-divider>
        </div>
        <div
          class="d-flex align-center justify-space-between my-2"
          style="width: 50%;"
        >
          <span class="text-normal"> Date de renouvellement </span>
          <span class="subtext"> 01/09/2022 </span>
        </div>
        <div
          class="d-flex align-center justify-space-between my-2"
          style="width: 50%;"
        >
          <span class="text-normal"> Objet de la transaction </span>
          <span class="subtext">
            Abonnement pour la saison {{ seasonSubscription }}
          </span>
        </div>
        <div
          class="d-flex align-center my-1 justify-space-between mb-3"
          style="width: 50%;"
        >
          <span class="text-normal"> Montant par saison </span>
          <span class="text-blue"> 40.00 € </span>
        </div>
        <div class="d-flex align-center">
          <v-checkbox
            v-model="acceptTerms"
            style="margin-top: 4px !important;"
          />
          <span class="text-subtitle">
            Annuler la prolongation tacite de l’abonnement</span
          >
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BaseModal from "../../../../components/utils/modals/BaseModal.vue";

export default {
  name: "IdFuseCancelSubscriptionModal",
  components: { BaseModal },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      acceptTerms: false,
    };
  },
  computed: {
    ...mapState({
      structureSubscriptionIdFuse: (state) =>
        state.structure.structureSubscriptionIdFuse,
    }),
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("user", ["currentUser"]),

    seasonSubscription() {
      return this.structureSubscriptionIdFuse[0]?.ID_Saison;
    },
    validSeason() {
      if (this.structureSubscriptionIdFuse.length < 1)
        return "31/08/2021-30/09/2022";
      return `${this.$moment(
        this.structureSubscriptionIdFuse.Z_DateAbonnement
      ).format("DD/MM/YYYY")} - ${this.$moment(
        new Date("2022-08-30").setFullYear(
          +this.structureSubscriptionIdFuse[0].ID_Saison
        )
      ).format("DD/MM/YYYY")}`;
    },
  },
  methods: {
    ...mapActions("structure", ["updateCreateSubcription"]),
    ...mapActions("utilisateurs", ["createFluxFinancierDetail"]),
    async cancelSubscription() {
      const subscriptionPayload = {
        id: this.structureSubscriptionIdFuse[0]?.id,
        EST_Active: false,
        ID_Structure: this.currentStructureId,
      };
      await this.updateCreateSubcription({ ...subscriptionPayload });

      // create flux financier
      const fluxFinancierPayload = {
        ID_Traitement: 0,
        ID_Saison: this.currentSaison.id,
        ID_Structure: this.currentStructureId,
        EST_Reglement: false,
        ID_Mouvement_Domaine: 5, // admin
        EST_Reccurent: true,
        EST_Debit: false,
        MontantHT: 40,
        MontantTVA: 0,
        ID_CategoryFlux: 1,
        ID_Utilisateur: this.currentUser.id,
        NomFluxFinancier: `Désabonnement ID Fuse pour la saison ${this.currentSaisonId}`,
        ID_FluxFinancierDebit: 0,
      };
      await this.createFluxFinancierDetail({
        flux: [fluxFinancierPayload],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-modal {
  font-family: Roboto;
  font-style: normal;
  color: #020918;
  .text-normal {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .subtext {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
  .text-subtitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  .text-blue {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #082b6e;
  }
  .text-green {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #00be76;
  }
  .alert-message {
    background: rgba(8, 43, 110, 0.1);
    border-left: 2px solid #082b6e;
    color: #082b6e;
    min-height: 50px;
    padding: 14px;
  }
}
</style>
