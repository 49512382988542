<template>
  <div class="emailing-id-fuse d-flex flex-column">
    <div
      class="header-emailing d-flex align-center justify-content-center mb-6"
    >
      <span class="id-title mr-2" v-text="'Emailing'" />
      <span class="text-green mr-2">Abonnement actif </span>
      <v-icon small color="#020918"> mdi-cog</v-icon>
      <Button
        color="#082B6E"
        class="id-fuse-btn"
        @click="isCancelSubscriptionModalOpen = true"
      >
        <v-icon medium color="#FFF" class="mr-2"> mdi-open-in-new</v-icon>Accès
        à ID Fuse
      </Button>
    </div>
    <div class="table-header d-flex align-center justify-space-between">
      <div class="d-flex flex-column position-relative">
        <span class="text-blue">Catégorie de liste</span>
        <InputSelect
          :items="structureCategorieList"
          :loading="isFetchingListeCategorie"
          item_value="id"
          item_text="Nom_ListeCategorie"
          placeholder="Sélectionner une catégorie"
          v-model="categorieSelected"
        />
      </div>
      <Button color="#082B6E" rounded @click.prevent="openGestionListModal"
        >Créer une liste
      </Button>
    </div>
    <div class="table-container">
      <v-data-table
        class="id-fuse-table"
        :headers="headers"
        :items="structureListFiltered"
        :loading="isFetchingListStructure"
        disable-sort
        hide-default-footer
      >
        <template #item.destinataire="{item}">
          <span>
            {{
              item.STR_StructureListeUtilisateurs &&
              item.STR_StructureListeUtilisateurs.length
            }}
          </span>
        </template>
        <template #item.actions="{item}">
          <div class="d-flex align-center justify-content-center">
            <v-icon medium color="#000000" class="mr-4" @click="openList(item)"
              >mdi-notebook-edit-outline</v-icon
            >
            <v-icon medium color="#000000" class="mr-4" @click="editList(item)"
              >mdi-pencil</v-icon
            >
            <v-icon medium color="#000000" @click="deleteList(item)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </div>
    <IdFuseCancelSubscriptionModal
      v-if="isCancelSubscriptionModalOpen"
      :is-open="isCancelSubscriptionModalOpen"
      @close="isCancelSubscriptionModalOpen = false"
    />
    <IdFuseEditCreateListModal
      v-if="isEditListModalOpen"
      :is-open="isEditListModalOpen"
      :is-edited="isEditingList"
      @close="
        {
          isEditListModalOpen = false;
          isEditingList = false;
        }
      "
      @open-participant-list="isParticipantListModalOpen = true"
    />
    <IdFuseParticipantListModal
      v-if="isParticipantListModalOpen"
      :is-open="isParticipantListModalOpen"
      @close="isParticipantListModalOpen = false"
    />
    <IDFuseUserInListModal
      modal-width="1200"
      v-if="isUserInListModalOpen"
      :is-open="isUserInListModalOpen"
      :id-liste="idListeSelected"
      @close="isUserInListModalOpen = false"
    />
  </div>
</template>

<script>
import Button from "@/components/utils/button.vue";
import InputSelect from "@/components/utils/select.vue";
import IdFuseCancelSubscriptionModal from "./modals/IdFuseCancelSubscriptionModal.vue";
import IdFuseEditCreateListModal from "./modals/IdFuseEditCreateListModal.vue";
import IdFuseParticipantListModal from "./modals/IdFuseParticipantListModal.vue";
import IDFuseUserInListModal from "./modals/IDFuseUserInListModal.vue";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "IdFuseWithSubscription",
  components: {
    Button,
    InputSelect,
    IdFuseCancelSubscriptionModal,
    IdFuseEditCreateListModal,
    IdFuseParticipantListModal,
    IDFuseUserInListModal,
  },
  data() {
    return {
      isCancelSubscriptionModalOpen: false,
      isEditListModalOpen: false,
      isParticipantListModalOpen: false,
      isFetchingListeCategorie: true,
      isFetchingListStructure: true,
      categorieSelected: null,
      isEditingList: false,
      isUserInListModalOpen: false,
      isFetchingListe: false,
      userInList: null,
      idListeSelected: null,
      list: [{ text: "Licencie", name: "Junior", destinataire: "4/18" }],
      headers: [
        {
          text: "Catégorie",
          value: "STR_StructureListeCategorie.Nom_ListeCategorie",
        },
        {
          text: "Nom de la liste",
          value: "NomListe",
        },
        {
          text: "Destinataires",
          align: "center",
          value: "destinataire",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapState({
      structureCategorieList: (state) => state.structure.structureCategorieList,
      structureListes: (state) => state.structure.structureList,
    }),
    structureListFiltered() {
      if (!this.categorieSelected) return this.structureListes;

      return this.structureListes.filter(
        (liste) =>
          liste.STR_StructureListeCategorie.id === this.categorieSelected
      );
    },
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions("structure", [
      "getStructureListCategorie",
      "getStructureListeBySeason",
      "createUpdateNewListStructure",
    ]),
    openGestionListModal() {
      this.$router.push({
        name: "gestion_des_utilisateurs__membres_de_mon_club",
        params: { IDListe: 0 },
      });
    },
    editList(item) {
      this.$router.push({
        name: "gestion_des_utilisateurs__membres_de_mon_club",
        params: { IDListe: item.id },
      });
    },
    async deleteList(item) {
      const payload = {
        id: item.id,
        EST_Actif: false,
      };
      await this.createUpdateNewListStructure({
        liste: payload,
      });
    },
    openList(item) {
      this.isUserInListModalOpen = true;
      this.idListeSelected = item.id;
      this.userInList = [...item.STR_StructureListeUtilisateurs];
    },
    async getData() {
      Promise.all([
        await this.getStructureListCategorie({
          ID_Structure: this.currentStructureId,
        }),
        await this.getStructureListeBySeason({
          ID_Structure: this.currentStructureId,
          ID_Saison: this.currentSaison.id,
        }),
      ]);
      this.isFetchingListeCategorie = false;
      this.isFetchingListStructure = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.emailing-id-fuse {
  width: 100%;
  font-family: Roboto !important;
  font-style: normal !important;
  .header-emailing {
    position: relative;
    .id-fuse-btn {
      position: absolute;
      right: 0px;
    }
    .text-green {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #00be76;
    }
    .id-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #020918;
      text-transform: uppercase;
    }
  }
  .table-header {
    background: #ebeef3;
    min-height: 80px;
    padding: 12px 24px;
    .text-blue {
      position: absolute;
      top: -5px;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #1c3d7a;
    }
  }
}
</style>
<style scoped>
::v-deep .v-data-table-header th {
  background: #39558b !important;
  color: white !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
}
::v-deep .id-fuse-table td {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000;
}
</style>
