var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',_vm._g({attrs:{"headerText":"Consulter la liste","is-open":_vm.isOpen,"btnValidationText":"Ajouter les participants selectionnés","is-action-needed":false},on:{"validate":function($event){return _vm.setNewParticipant()}},scopedSlots:_vm._u([{key:"modal-content",fn:function(){return [_c('div',{staticClass:"content-modal d-flex flex-column px-1"},[_c('div',{staticClass:"d-flex align-center my-4"},[_c('span',{staticClass:"text-subtitle",domProps:{"textContent":_vm._s('Les personnes qui sont présentes dans la liste')}}),_c('v-divider',{staticClass:"ml-4",attrs:{"color":"#dfe5f9"}})],1),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"id-fuse-table",attrs:{"headers":_vm.headers,"items":_vm.participantListe,"disable-sort":""},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{domProps:{"textContent":_vm._s(
                ((item.UTI_Utilisateur.CT_Nom) + " " + (item.UTI_Utilisateur.CT_Prenom))
              )}}):_vm._e()]}},{key:"item.Naissance",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.UTI_Utilisateur.DN_DateNaissance).format( "DD/MM/YYYY" ))+" ")])]}},{key:"item.LicenceNumero",fn:function(ref){
              var item = ref.item;
return [(item.UTI_Utilisateur.LicenceNumero == null)?_c('v-chip',{attrs:{"color":"grey","text-color":"white"},domProps:{"textContent":_vm._s('N.C')}}):_c('v-chip',{attrs:{"color":"primary","text-color":"white"},domProps:{"textContent":_vm._s(_vm.formatToLicence(item.UTI_Utilisateur.LicenceNumero))}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","outlined":"","color":"red"},on:{"click":function($event){return _vm.removeUserFromList(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Retirer la personne de la liste")])])]}}])})],1)])]},proxy:true}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }