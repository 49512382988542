<template>
  <div class="emailing-container">
    <div v-if="initializing" style="width: 100% !important; height: 100%;">
      <v-skeleton-loader
        v-bind="$attrs"
        type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
        max-width="1000"
        min-width="800"
      ></v-skeleton-loader>
    </div>
    <div v-else class="d-flex flex-column">
      <id-fuse-with-subscription
        v-if="isSubscriptionActive"
        @open-cancel-subscription="isCancelSubscriptionModalOpen = true"
        @open-edit-create-list="isEditListOpen = true"
      />
      <IdFuseNoSubscription
        v-else
        @open-subscription-modal="isSubscriptionModalOpen = true"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IdFuseNoSubscription from "./IdFuseNoSubscription.vue";
import IdFuseWithSubscription from "./IdFuseWithSubscription.vue";
export default {
  components: {
    IdFuseNoSubscription,
    IdFuseWithSubscription,
  },
  name: "IdFuseEmailing",
  data() {
    return {
      initializing: true,
    };
  },
  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapState({
      structureSubscriptionIdFuse: (state) =>
        state.structure.structureSubscriptionIdFuse,
    }),
    isSubscriptionActive() {
      if (!this.structureSubscriptionIdFuse?.length) return false;
      if (
        this.structureSubscriptionIdFuse?.length < 1 ||
        !this.structureSubscriptionIdFuse[0]?.EST_Active
      )
        return false;

      return true;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("structure", ["getStructureSubscription"]),
    async initialize() {
      await this.getStructureSubscription({
        ID_Structure: this.currentStructureId,
      });
      this.initializing = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.emailing-container {
  width: 100%;
}
</style>
