<template>
  <BaseModal
    headerText="Abonnement à la plateforme d’emailing"
    :is-open="isOpen"
    v-on="$listeners"
    @validate="subscribeToIDfuse()"
  >
    <template #modal-content>
      <div class="content-modal d-flex flex-column px-1">
        <div class="d-flex align-center my-6">
          <div
            class="d-flex align-center justify-space-between"
            style="width: 50%;"
          >
            <span class="text-normal"> Sélection de la saison </span>
            <InputSelect
              :items="validSeason"
              v-model="selectedSeason"
              style="width: 250px !important;"
            />
          </div>
          <span
            class="subtext ml-6"
            v-text="`Période de validité : ${validAbonnement}`"
          />
        </div>
        <div class="d-flex align-center my-2">
          <span
            v-text="'Description de la transaction'"
            class="text-subtitle"
          />
          <v-divider class="ml-4" color="#dfe5f9"></v-divider>
        </div>
        <div
          class="d-flex align-center justify-space-between my-2"
          style="width: 50%;"
        >
          <span class="text-normal"> Objet de la transaction </span>
          <span class="subtext">
            Abonnement pour la saison {{ selectedSeason }}
          </span>
        </div>
        <div
          class="d-flex align-center my-1 justify-space-between mb-3"
          style="width: 50%;"
        >
          <span class="text-normal"> Montant par saison </span>
          <span class="text-blue"> 40.00 € </span>
        </div>
        <div class="alert-message">
          <span style="font-weight: bold;">Information : </span>
          <span>
            Le prélèvement de 40.00 € s’effectura sur le compte de la structure
            avec un renouvellement tacite à chaque saison.</span
          >
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../../components/utils/modals/BaseModal.vue";
import InputSelect from "@/components/utils/select.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "IdFuseSubscriptionModal",
  components: { BaseModal, InputSelect },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("user", ["currentUser"]),

    validSeason() {
      return [+this.currentSaison.id, +this.currentSaison.id + 1];
    },
    validAbonnement() {
      return `${this.$moment(
        new Date("2022-09-30").setFullYear(this.selectedSeason - 1)
      ).format("DD/MM/YYYY")} - ${this.$moment(
        new Date("2022-08-31").setFullYear(this.selectedSeason)
      ).format("DD/MM/YYYY")}`;
    },
  },
  data() {
    return {
      selectedSeason: null,
    };
  },
  created() {
    this.selectedSeason = +this.currentSaison.id;
  },
  methods: {
    ...mapActions("structure", ["updateCreateSubcription"]),
    ...mapActions("utilisateurs", ["createFluxFinancierDetail"]),
    async subscribeToIDfuse() {
      const subscriptionPayload = {
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSeason,
        Z_DateRenouvellement: this.$moment(
          new Date("2022-09-30").setFullYear(this.selectedSeason)
        ).toISOString(),
      };
      await this.updateCreateSubcription({ ...subscriptionPayload });
      // create flux financier
      const fluxFinancierPayload = {
        ID_Traitement: 0,
        ID_Saison: this.currentSaison.id,
        ID_Structure: this.currentStructureId,
        EST_Reglement: true,
        ID_Mouvement_Domaine: 5, // admin
        EST_Reccurent: true,
        EST_Debit: true,
        MontantHT: 40,
        MontantTVA: 0,
        ID_CategoryFlux: 1,
        ID_Utilisateur: this.currentUser.id,
        NomFluxFinancier: `Abonnement ID Fuse pour la saison ${this.currentSaisonId}`,
        ID_FluxFinancierDebit: 0,
      };
      await this.createFluxFinancierDetail({
        flux: [fluxFinancierPayload],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-modal {
  font-family: Roboto;
  font-style: normal;
  color: #020918;
  .text-normal {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .subtext {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .text-subtitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  .text-blue {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #082b6e;
  }

  .alert-message {
    background: rgba(8, 43, 110, 0.1);
    border-left: 2px solid #082b6e;
    color: #082b6e;
    min-height: 50px;
    padding: 14px;
  }
}
</style>
