<template>
  <div class="emailing-id-fuse d-flex flex-column">
    <span class="id-title text-center" v-text="'Emailing'" />
    <div class="alert-container d-flex flex-column">
      <div class="my-1">
        <span class="alert-1"> Informations : </span>
        <span>
          Vous pouvez vous abonner pour émettre des campagnes emailing vers les
          membres de votre structure.
        </span>
      </div>
      <span class="my-1"
        >Un outil de gestion de listes de contacts intégré à myFFME vous aidera
        afin de cibler vos campagnes efficacement.</span
      >
      <span class="mb-8"
        >L’abonnement n’est pas annuel, il est pour la saison en cours*.</span
      >
      <span class="mb-1"
        >*Si vous vous abonnez en cours de saison votre abonnement s’arrêtera
        tout de même à la fin de celle-ci.</span
      >
    </div>

    <Button
      color="#082B6E"
      style="color: #ffffff !important;"
      class="mr-auto"
      rounded
      @click="isSubscriptionModalOpen = true"
    >
      S’abonner à ID Fuse</Button
    >
    <IdfuseSubscriptionModal
      :is-open="isSubscriptionModalOpen"
      @close="isSubscriptionModalOpen = false"
    />
  </div>
</template>

<script>
import Button from "../../../components/utils/button.vue";
import IdfuseSubscriptionModal from "./modals/IdfuseSubscriptionModal.vue";
export default {
  name: "IdFuseNoSubscription",
  components: { Button, IdfuseSubscriptionModal },
  data() {
    return {
      isSubscriptionModalOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.emailing-id-fuse {
  width: 100%;
  font-family: Roboto !important;
  font-style: normal !important;
  .id-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #020918;
    text-transform: uppercase;
  }
  .alert-container {
    margin: 32px 0px;
    width: 100%;
    padding: 8px 12px;
    font-weight: normal;
    color: #082b6e;
    font-size: 14px;
    line-height: 20px;
    background: rgba(8, 43, 110, 0.1);
    border-left: 2px solid #082b6e;
    .alert-1 {
      font-weight: bold;
    }
  }
}
</style>
